import React from 'react'

class App extends React.Component{
  constructor(props){
    super(props);
    this.state = {
      targetDate: new Date("October 25, 2024 00:00:00").getTime(),
      timeLeft: {
        days:0,
        hours:0,
        minutes:0,
        seconds:0
      }
    }
  }

  componentDidMount(){
    const intervalID = setInterval(this.handleUpdateTimer, 1000)
    return () => clearInterval(intervalID)
  }

  handleUpdateTimer = () => {
    const now = new Date().getTime()
      const distance = this.state.targetDate - now
      if(distance > 0){
        const days = Math.floor(distance / (1000 * 60 * 60 * 24));
        const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((distance % (1000 * 60)) / 1000);

        this.setState({
          timeLeft: {
            days: days,
            hours: hours,
            minutes: minutes,
            seconds: seconds
          }
        })
      }
      else{
        this.setState({
          timeLeft: {
            days: 0,
            hours: 0,
            minutes:0,
            seconds: 0
          }
        })
      }
  }

  render(){
    return(
      <div className='general-container'>
        <div className='general-title'>¿Lo común te cansa?</div>
        <div className='general-title'>Hay algo mejor esperando por ti.</div>
        <div className='general-title'>Preparate para el cambio</div>
        <div className='timer-container'>
          {this.state.timeLeft.days}D:{this.state.timeLeft.hours}H:{this.state.timeLeft.minutes}M:{this.state.timeLeft.seconds}S
        </div>
        <div className='general-hashtag-container'>
          #LaXXXXEsUnActoPolítico
        </div>
      </div>
    )
  }
}

export default App